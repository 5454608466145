import circleRed from "../images/circleRed.png";
import circleGreen from "../images/circleGreen.png";
import circleBlue from "../images/circleBlue.png";
import circleYellow from "../images/circleYellow.png";

const pinImages = {
  red: {
    scale: 1.0,
    opacity: 0.8,
    source: circleRed,
  },
  green: {
    scale: 1.0,
    opacity: 0.5,
    source: circleGreen,
  },
  blue: {
    scale: 1.0,
    opacity: 0.5,
    source: circleBlue,
  },
  yellow: {
    scale: 1.0,
    opacity: 0.8,
    source: circleYellow,
  },
};

export default pinImages;
