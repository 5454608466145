/**
 * Creates the map.
 * The map is an OpenLayers map which connects to the "map" div.
 */
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import WMS from "ol/source/TileWMS";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";
import { Style, Fill } from "ol/style";
import { createPinLayer } from "./pinCreator";
import GeoJSONTerminator from "./terminator";
import "ol/ol.css";
import Overlay from "ol/Overlay";
import { transform } from "ol/proj";
import { createPinModal } from "./modalCreator";
import { getJSONData } from "./utilities";
import snewsFacilities from "../data/snewsFacilities";
import pinImages from "../data/pinImages";

const INITIAL_ZOOM = 2;
const MAX_ZOOM = 20;
const INITIAL_LON_LAT = [0, 0];
const MAP_EXTENTS = [-180, -95, 180, 90];
const TERMINATOR_OPACITY = 0.3;
const MAP_PROJECTION = "EPSG:4326";
const FACILITY_LOCATIONS_URL =
  "https://raw.githubusercontent.com/astropy/astropy-data/gh-pages/coordinates/sites.json";

/**
 * Create the background layers.
 */
const OSMLayer = new TileLayer({
  source: new OSM({
    wrapX: false,
  }),
  zIndex: -10,
});

const WMSLayer = new TileLayer({
  source: new WMS({
    url: "https://ahocevar.com/geoserver/wms",
    params: {
      LAYERS: "ne:NE1_HR_LC_SR_W_DR",
    },
    wrapX: false,
  }),
  zIndex: -10,
});

/**
 * Create the day/night terminator.
 */
const geoJSONTerminator = new GeoJSONTerminator();
const terminatorLayer = new VectorLayer({
  source: new VectorSource({
    features: new GeoJSON().readFeatures(geoJSONTerminator, {
      featureProjection: MAP_PROJECTION,
    }),
  }),
  style: new Style({
    fill: new Fill({
      color: "rgb(0, 0, 0)",
    }),
  }),
  opacity: TERMINATOR_OPACITY,
});

/**
 * Set up the view for map boundaries, etc.
 */
const view = new View({
  projection: MAP_PROJECTION,
  center: INITIAL_LON_LAT,
  zoom: INITIAL_ZOOM,
  maxZoom: MAX_ZOOM,
  extent: MAP_EXTENTS,
  smoothExtentConstraint: false,
});

/**
 * Set up the pin popover overlay for location information.
 */
let currentPopover = null;
const popoverOverlay = new Overlay({
  element: document.createElement("div"),
});

/**
 * Create the actual map with the layers, overlays, and view defined above.
 */
const map = new Map({
  target: "map",
  layers: [OSMLayer, terminatorLayer],
  overlays: [popoverOverlay],
  view: view,
});

/**
 * Set up click events for showing pin information.
 */
map.on("singleclick", (event) => {
  currentPopover?.hide();
  let foundPin = false;

  map.forEachFeatureAtPixel(event.pixel, (feature, layer) => {
    if (foundPin || layer.get("purpose") !== "pin") return;
    foundPin = true;

    let lonLat = feature.getGeometry().getCoordinates();

    if (MAP_PROJECTION != "EPSG4326") {
      lonLat = transform(lonLat, MAP_PROJECTION, "EPSG:4326");
    }

    updatePinPopover(layer.get("name"), lonLat[0], lonLat[1]);
  });
});

/**
 * Asynchronously requests weather information.
 */
const updatePinPopover = (name, lon, lat) => {
  popoverOverlay.setPosition([lon, lat]);

  popoverOverlay.getElement().setAttribute("title", name);
  popoverOverlay
    .getElement()
    .setAttribute("data-bs-content", createPinModal(lon, lat));

  currentPopover?.dispose();

  currentPopover = new bootstrap.Popover(popoverOverlay.getElement(), {
    container: popoverOverlay.getElement(),
    html: true,
  });

  currentPopover.show();
};

/**
 * Toggles the day/night terminator.
 */
let showingTerminator = true;
const toggleTerminator = () => {
  if (showingTerminator) {
    map.removeLayer(terminatorLayer);
  } else {
    map.addLayer(terminatorLayer);
  }
  showingTerminator = !showingTerminator;
};

/**
 * Filters for certain pins based on their type.
 */
const filterPins = (filter) => {
  map.getLayers().forEach((layer) => {
    if (layer.get("purpose") !== "pin") return;

    layer.setVisible(filter === "all" || layer.get("type") === filter);
  });
};

/**
 * Handler for toggling map layers.
 */
let showingOSM = true;
const toggleMapLayers = () => {
  if (showingOSM) {
    map.removeLayer(OSMLayer);
    map.addLayer(WMSLayer);
  } else {
    map.removeLayer(WMSLayer);
    map.addLayer(OSMLayer);
  }

  showingOSM = !showingOSM;
};

/**
 * Add pins here.
 */

const addPins = () => {
  // Add SNEWS neutrino facilities.
  Object.keys(snewsFacilities).forEach((key) => {
    let facility = snewsFacilities[key];
    let name = key;

    for (let i = 0; i < facility.aliases.length; ++i) {
      name += " / " + facility.aliases[i];
    }

    let image;
    switch (facility.status) {
      case "current":
        image = pinImages.yellow;
        break;
      case "soon offline":
        image = pinImages.blue;
        break;
      case "future":
        image = pinImages.green;
        break;
    }

    map.addLayer(
      createPinLayer(facility.lon, facility.lat, name, facility.status, image)
    );
  });

  // Add facilities from the astropy repo.
  getJSONData(FACILITY_LOCATIONS_URL, (data) => {
    Object.keys(data).forEach((item) => {
      let facility = data[item];
      let name = facility.name;

      for (let i = 0; i < facility.aliases.length; ++i) {
        name += " / " + facility.aliases[i];
      }

      map.addLayer(
        createPinLayer(
          facility.longitude,
          facility.latitude,
          name,
          "observatory",
          pinImages.red
        )
      );
    });
  });
};

addPins();

export { filterPins, toggleMapLayers, toggleTerminator };
