import { filterPins, toggleMapLayers, toggleTerminator } from "./map";

const onFilterChange = (event) => {
  filterPins(event.value);
};

const onToggleLayer = () => {
  toggleMapLayers();
};

const onToggleTerminator = () => {
  toggleTerminator();
};

window.onFilterChange = onFilterChange;
window.onToggleLayer = onToggleLayer;
window.onToggleTerminator = onToggleTerminator;
