/**
 * Defines helpful tools.
 */

const getJSONData = (url, callback) => {
  const request = new XMLHttpRequest();
  request.responseType = "json";
  request.onreadystatechange = () => {
    if (request.readyState != 4 || request.status != 200) return;

    callback(request.response);
  };
  request.open("GET", url, true);

  request.send();
};

export { getJSONData };
