let snewsFacilities = {
  "SNO+": {
    lon: -81.201,
    lat: 46.475,
    status: "current",
    aliases: ["HALO"],
  },
  NOvA: {
    lon: -92.833611,
    lat: 48.379167,
    status: "current",
    aliases: [],
  },
  LZ: {
    lon: -88.257222,
    lat: 41.831944,
    status: "current",
    aliases: [],
  },
  DUNE: {
    lon: -88.257222,
    lat: 41.831944,
    status: "future",
    aliases: ["Deep Underground Neutrino Experiment"],
  },
  KM3NeT: {
    lon: 42.25,
    lat: 48.34,
    status: "future",
    aliases: ["Cubic Kilometre Neutrino Telescope"],
  },
  LVD: {
    lon: 13.57,
    lat: 42.46,
    status: "soon offline",
    aliases: ["Large Volume Detector"],
  },
  Borexino: {
    lon: 13.57,
    lat: 42.46,
    status: "soon offline",
    aliases: [],
  },
  "DarkSide-50": {
    lon: 13.516389,
    lat: 42.420556,
    status: "current",
    aliases: [],
  },
  "DarkSide-20k": {
    lon: 13.516389,
    lat: 42.420556,
    status: "future",
    aliases: [],
  },
  XENON: {
    lon: 13.516389,
    lat: 42.420556,
    status: "current",
    aliases: [],
  },
  Darwin: {
    lon: 13.516389,
    lat: 42.420556,
    status: "future",
    aliases: [],
  },
  "HALO-1kt": {
    lon: 13.516389,
    lat: 42.420556,
    status: "future",
    aliases: [],
  },
  "Super-Kamiokande": {
    lon: 137.3082262,
    lat: 36.4267096,
    status: "current",
    aliases: [],
  },
  KamLAND: {
    lon: 137.3153,
    lat: 36.4225,
    status: "current",
    aliases: ["Kamioka Liquid Scintillator Antineutrino Detector"],
  },
  "Daya Bay Reactor Neutrino Experiment": {
    lon: 114.5431,
    lat: 22.5953,
    status: "current",
    aliases: [],
  },
  JUNO: {
    lon: 112.51867,
    lat: 22.11827,
    status: "current",
    aliases: ["Jiangmen Underground Neutrino Observatory"],
  },
  PandaX: {
    lon: 101.7,
    lat: 28.2,
    status: "current",
    aliases: ["Particle and Astrophysical Xenon Detector"],
  },
  IceCube: {
    lon: -63.453056,
    lat: -89.99,
    status: "current",
    aliases: ["IceCube Neutrino Observatory"],
  },
};

export default snewsFacilities;
