/**
 * Set up handling weather API requests.
 * Opens a modal above the selected pin when request completes.
 */

const createPinModal = (lon, lat) => {
  let lonRounded = Math.round(lon * 100) / 100;
  let latRounded = Math.round(lat * 100) / 100;
  return `
  <a href="https://clearoutside.com/forecast/${lat}/${lon}"><img class="img-fluid" src="https://clearoutside.com/forecast_image_large/${latRounded}/${lonRounded}/forecast.png" /></a>
  `;
};

export { createPinModal };
