import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import VectorSource from "ol/source/Vector";
import { Style } from "ol/style";
import Icon from "ol/style/Icon";
import VectorLayer from "ol/layer/Vector";

/**
 * Creates and returns a pin layer for the map.
 */
const createPinLayer = (lon, lat, name, type, imageData) => {
  const pinFeature = new Feature({
    geometry: new Point([lon, lat]),
  });

  const pinSource = new VectorSource({
    features: [pinFeature],
  });

  const pinStyle = new Style({
    image: new Icon({
      src: imageData.source,
      scale: imageData.scale,
      opacity: imageData.opacity,
    }),
  });

  return new VectorLayer({
    source: pinSource,
    style: pinStyle,
    purpose: "pin",
    name: name,
    type: type,
  });
};

export { createPinLayer };
